export const allMinDivReq = () => {
  const minReqs = {
    oper: {
      RSU: {
        Class_I: 10,
        Class_II: 12,
        Class_III: 2,
        Class_IV: 2,
        Class_V: 5,
        Recall_completed_case: 1,
        Recall_any: 1,
        Exam_Class_II: 1,
        Exam_Class_V: 1,
        Polishing: 0,
        Sealant: 0,
        PRR: 0,
        Caries_control: 0,
        Emergency_tx: 0,
        Inlay: 0,
        Onlay: 0,
        Diastema_closure: 0,
        Veneer: 0,
        Minimum_total_R: 60,
      },
      CDA: {
        Class_I: 5,
        Class_II: 6,
        Class_III_or_IV: 5,
        Class_V: 5,
        Any_class: 5,
      },
    },
    perio: {
      RSU: {
        Case_G: 4,
        Case_P: 1,
        Complexities: 7,
        OHI_1st_Exam: 1,
        SRP_1st_Exam: 1,
      },
      CDA: {
        //Case_G: 0,
        Case_P: 1,
        CDA_Cases: 9,
        OHI_2n_Exam: 1,
        SRP_2nd_Exam: 1,
      },
    },
    endo: {
      RSU: {
        RCT_Anterior_or_Premolar: 1,
        RCT_Molar: 1,
        Emergency_RCT: 1,
        Talk_case: 1,
        Recall_6_months: 1,
        Exam_RCT: 1,
      },
      CDA: {
        RCT_Anterior_or_Premolar: 1,
        Exam_RCT: 1,
      },
    },
    prosth: {
      RSU: {
        CD_Upper: 1,
        CD_Lower: 1,
        MRPD: 1,
        ARPD: 2,
        Crown: 2,
        Post_Core_Crown: 1,
        Bridge_3_units: 1,
        Exam_design_RPD: 1,
        Exam_crown_preparation: 1,
      },
      CDA: {
        CD_Upper: 1,
        CD_Lower: 1,
        MRPD: 1,
        ARPD: 2,
        Crown: 2,
        Post_Core_Crown: 1,
        Bridge_3_units: 1,
      },
    },
    diag: {
      RSU: {
        Complete_case_examination: 20,
        Assistant: 2,
        CPC_or_Journal_club: 1,
        Complete_splint: 1,
        Recall_cases_splint: 2,
      },
      CDA: {
        Complete_case_examination: 10,
        Chief_complaint_examination: 25,
        Biopsy_Blood_chemistry_or_soft_tissue_lesion_interpretation: 1,
        TMDs_case_examination: 1,
      },
    },
    radio: {
      RSU: {
        Full_mouth_periapical_radiograph: 1,
        Periapical_radiograph: 30,
        Bitewing_radiograph: 20,
        Extraoral_and_Special_technique_radiograph: 10,
        Film_interpretation: 20,
        Journal_club: 1,
      },
      CDA: {
        Exam_periapical_radiograph_anterior_teeth: 1,
        Exam_periapical_radiograph_posterior_teeth: 1,
        Exam_bitewing_radiograph: 1,
      },
    },
    sur: {
      RSU: {
        Aseptic_station: 1,
        Suture_station: 1,
        Vital_sign_station: 1,
        IANB_exam: 1,
        Impact_in_model: 1,
        Extraction: 18,
        Impact: 1,
        Exam_extraction_RSU: 1,
        Exam_impact_RSU: 1,
      },
      CDA: {
        Extraction: 13,
        Impact: 1,
        Exam_extraction_CDA: 1,
        Exam_impact_CDA: 1,
      },
    },
    ortho: {
      RSU: {
        Charting: 1,
        Photograph_taking: 1,
        Impression_taking_Upper: 1,
        Impression_taking_Lower: 1,
        Removable_appliance: 1,
        Inserting_removable_appliance: 1,
        Assisting_adjust_fixed_appliance: 1,
        Plaster_Pouring: 1,
        Model_Trimming: 1,
        Case_analysis: 1,
      },
      CDA: {
        Inserting_removable_appliance: 1,
        Case_analysis: 1,
      },
    },
    pedo: {
      RSU: {
        Comprehensive_examination_and_treatment_plan_in_new_patient: 2,
        Comprehensive_examination_and_treatment_plan_in_recall_patient: 3,
        Photographs_and_Radiographs: 1,
        Caries_risk_assessment_and_Management: 5,
        Sealant: 8,
        Filling: 6,
        Primary_molar_class_II_restoration: 2,
        Stainless_steel_crown_in_posterior_teeth: 1,
        Pulpectomy_Step_OC_and_LT_or_Pulpotomy: 1,
        Pulpectomy_Step_MI_and_FRC: 1,
        Extraction: 2,
        Miscellaneous_work: 1,
        Exam_Inferior_alveolar_nerve_block_injection: 1,
        Exam_Rubber_dam_application: 1,
      },
      CDA: {
        Comprehensive_examination_and_treatment_plan: 5,
        Caries_risk_assessment_and_Management: 5,
        Filling_or_PRR: 10,
        Pulpectomy_or_Pulpotomy: 1,
        Stainless_steel_crown: 1,
        Sealant: 5,
        Extraction: 2,
      },
    },
  };

  return minReqs;
};
